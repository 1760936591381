import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout.js";

const Nathanial = () => {
  return (
    <Layout>
      <Head title="Nathanial Dorsky" />
      <h3>Nathanial Dorsky</h3>
      <h4>
        <FaGlobe />
        San Francisco, CA
      </h4>
      <p>
        <OutboundLink href="https://nathanieldorsky.net/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://twitter.com/ndorsky">Twitter</OutboundLink>
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/rXiiRj0XszQ?si=Wqyf69Z-Aw9u5h30"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6YndX5mnd0Q?si=WNPpVK2MZ_AFNuBS"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/jF8XMhi_T8I?si=ALYvZgQRUacUBJ56"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Nathanial;
